import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import {
  Button,
  Modal,
  Row,
  message,
  notification,
  Breadcrumb,
  Tooltip,
  Select,
  Form,
  Layout,
} from 'antd'
import {
  QuestionCircleOutlined,
  CloseCircleFilled,
  ArrowLeftOutlined
} from '@ant-design/icons'
import 'ol/ol.css'
import VectorSource from 'ol/source/Vector.js'
import LineString from 'ol/geom/LineString.js'
import Polygon from 'ol/geom/Polygon.js'
import MultiLineString from 'ol/geom/MultiLineString.js'
import MultiPolygon from 'ol/geom/MultiPolygon.js'
import Feature from 'ol/Feature.js'

import Map from './components/map'
import { PanelLayout, UploadModal } from '../../components'
import { getLayersList, getLayerGeometryType, downloadFile } from '../../helpers'
import CalculatorsTable from './components/calculators-table'
import './style.less'
import { useDispatch, useSelector } from 'react-redux'
import { syncField } from '../../models/mordovia/actions'
import {
  currentGeocodeFeature,
  currentSyncedField
} from '../../models/mordovia/selectors'
import { GEOCODE_MAP_URL_BASE } from '../../environment'
const { PanelHeader, PanelContent } = PanelLayout
import request from '../../utils/request'

const DEFAULT_PAGE_SIZE = 10
const LAYER_TYPE = 'verification_area'

const FieldRegistry = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const fieldShape = useSelector(state => currentGeocodeFeature(state))
  const syncResult = useSelector(state => currentSyncedField(state))

  const [mapExtent, setMapExtent] = useState([])
  const [openedMapId, setOpenedMapId] = useState()
  const [openedGeohubLayerId, setOpenedGeohubLayerId] = useState()
  const [openedAgrohubLayerId, setOpenedAgrohubLayerId] = useState()
  const [activeLayerId, setActiveLayerId] = useState()
  const [activeVerificationId, setActiveVerificationId] = useState()
  const [selectLayerOptions, setSelectLayerOptions] = useState([])
  const [columnOptions, setColumnOptions] = useState([])
  const [visibleLayers, setVisibleLayers] = useState([])
  const [visibleVerifications, setVisibleVerifications] = useState([])
  const [openedLayerName, setOpenedLayerName] = useState()
  const [openedMapName, setOpenedMapName] = useState()
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [addModalForm] = Form.useForm()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalSize, setTotalSize] = useState(0)
  const [mapLayers, setMapLayers] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [areaTypes, setAreaTypes] = useState(false)
  const [selectedAddModalCadastralNumbersColumn, setSelectedAddModalCadastralNumbersColumn] = useState()
  const [selectedAddModalLandTypesColumn, setSelectedAddModalLandTypesColumn] = useState()
  const [selectedAddModalNumbersColumn, setSelectedAddModalNumbersColumn] = useState()
  const [selectedAddModalLayer, setSelectedAddModalLayer] = useState()

  const [isOpenedAddModal, setIsOpenedAddModal] = useState(false)
  const [isOpenedUpdateModal, setIsOpenedUpdateModal] = useState(false)
  const [isOpenedDeleteModal, setIsOpenedDeleteModal] = useState(false)
  const [isOpenedShowPhotosModal, setIsOpenedShowPhotosModal] = useState(false)
  const [isOpenedAttachPhotosModal, setIsOpenedAttachPhotosModal] = useState(false)
  const [isOpenedAttachDocsModal, setIsOpenedAttachDocsModal] = useState(false)

  const updateLayerInfo = (geohubLayerId) => {
    if (Array.isArray(mapLayers)) {
      setVisibleLayers(mapLayers.filter(x => {
        return x?.layer_type === LAYER_TYPE && x?.is_deleted !== true
      }))
      const layerObject = mapLayers.find(x => x?.layer_id_ghb === geohubLayerId)
      if (layerObject) {
        setOpenedLayerName(layerObject.name)
        setOpenedAgrohubLayerId(layerObject.id)
        updateLayerVerifications(layerObject.id, 1)
      }
    }
  }

  useEffect(() => {
    updateLayerInfo(openedGeohubLayerId)
  }, [mapLayers])

  const updateLayersByMap = async (mapId) => {
    try {
      const res = await request.get({
        url: `/api/v1/mordovia/layer/`,
        body: {
          page: 1,
          page_size: 300,
          filter: JSON.stringify({
            'map.map_id_ghb': mapId,
            'layer_type': 'verification_area',
            'is_deleted': false
          })
        }
      })
      const isChangedSize = mapLayers.length !== res.count
      if (Array.isArray(res.results)) {
        setMapLayers(res.results)
      }
      return isChangedSize
    } finally { }
  }

  const updateMapInfo = async (mapId) => {
    try {
      const res = await request.get({
        url: `/api/v1/mordovia/map/`,
        body: {
          filter: JSON.stringify({
            map_id_ghb: mapId
          })
        }
      })
      const maps = res.results
      if (Array.isArray(maps) && maps?.length) {
        setOpenedMapName(maps[0].name)
      }
    } finally {

    }
  }

  useEffect(() => {
    const mapId = Number(match?.params?.mapId)
    if (match?.params?.mapId && !Number.isNaN(mapId)) {
      setOpenedMapId(mapId)
      localStorage.setItem('teleagronom-last-opened-map', mapId)
      updateLayersByMap(mapId)
      updateMapInfo(mapId)
      getGeohubMapTree(mapId)
      loadAreaTypes()
    } else {
      setOpenedMapName()
      setOpenedMapId()
    }
  }, [match.params.mapId])

  useEffect(() => {
    setOpenedAgrohubLayerId()
    setVisibleVerifications([])
    setCurrentPage(1)
    const layerId = Number(match?.params?.layerId)
    if (match?.params?.layerId && !Number.isNaN(layerId)) {
      setTotalSize(0)
      setOpenedGeohubLayerId(layerId)
      updateLayerInfo(layerId)
      getLayerInfo(layerId)
    } else {
      setTotalSize(visibleLayers.length)
      setOpenedLayerName()
      setOpenedGeohubLayerId()
    }
  }, [match.params.layerId])

  const loadAreaTypes = async () => {
    if (areaTypes.length) return
    try {
      const response = await request.get({
        url: '/api/v1/verification_area/area_type/'
      })
      if (Array.isArray(response?.results)) {
        setAreaTypes(response.results.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        }))
      }
    } finally {
    }
  }

  const updateLayerVerifications = async (agrohubLayerId, page, filter, sort) => {
    if (!agrohubLayerId) return
    try {
      setTableLoading(true)
      const response = await request.get({
        url: '/api/v1/verification_area/verification_area/',
        body: {
          page: page,
          page_size: DEFAULT_PAGE_SIZE,
          sort: sort,
          filter: JSON.stringify({
            'polygon.layer': agrohubLayerId,
            ...filter
          })
        }
      })
      if (typeof response.count === 'number') {
        setTotalSize(response.count)
      }
      if (Array.isArray(response?.results)) {
        setVisibleVerifications(response.results)
      }
    } finally {
      setTableLoading(false)
    }
  }

  useEffect(() => {
    const result = [{
      id: 'toMaps',
      label: 'Мои карты',
      link: '/'
    }]
    if (openedMapName) {
      result.push({
        id: 'toRegistry',
        label: openedMapName,
        link: `/field-registry/${openedMapId}/`
      })
      result.push({
        id: 'toVerificationArea',
        label: 'Участки проверки',
        link: `/verification-area/${openedMapId}/`
      })
      if (openedLayerName) {
        result.push({
          id: 'toVerificationAreaLayer',
          label: openedLayerName
        })
      }
    }
    setBreadcrumbs(result.map(item => {
      return (
        <Breadcrumb.Item key={item.id} style={{ fontSize: '24px' }}>
          <Link to={item.link}>{item.label}</Link >
        </Breadcrumb.Item>
      )
    }))
  }, [openedLayerName, openedMapName])

  useEffect(() => {
    if (fieldShape?.error?.length > 0) {
      message.error('Ошибка при получении объекта с geohub')
    }
  }, [fieldShape])

  const getGeohubMapTree = async (mapId) => {
    try {
      setTableLoading(true)
      const response = await request.get({
        url: `${GEOCODE_MAP_URL_BASE}/api/maps/${mapId}`
      })
      if (response.groups) {
        const mapLayers = getLayersList(response.groups)
        const polygonLayers = mapLayers.filter(layer => {
          const layerType = getLayerGeometryType(layer)
          return ['MultiPolygon', 'Polygon'].includes(layerType)
        })
        if (Array.isArray(polygonLayers)) {
          setSelectLayerOptions(polygonLayers.map(x => ({
            label: x?.name,
            value: x?.id
          })))
        }
      }
    } finally {
      setTableLoading(false)
    }
  }

  const clearAddModalSelects = () => {
    setSelectedAddModalCadastralNumbersColumn()
    setSelectedAddModalLandTypesColumn()
    setSelectedAddModalNumbersColumn()
  }

  const clearAllAddFormFields = () => {
    addModalForm?.setFieldsValue({
      layerName: null,
      numbersColumn: null,
      cadastralNumbersColumn: null,
      landTypesColumn: null
    })
    setSelectedAddModalLayer()
    clearAddModalSelects()
    setColumnOptions([])
    setIsOpenedAddModal(false)
  }

  useEffect(() => {
    if (syncResult?.isResult && syncResult?.results?.length > 0) {
      notification.success({
        message: 'Объекты подготовлены!',
        description: 'Подготовка объекта произошла успешно!'
      })

      const resultingArray = []
      if (Array.isArray(syncResult?.results)) {
        syncResult?.results?.slice(0, 9)?.forEach(reportObj => {
          resultingArray.push(reportObj)
        })
      }
      setIsOpenedAddModal(false)
    } else if (!syncResult?.isResult && syncResult?.error === 'backend error') {
      notification.error({
        message: 'Произошла ошибка'
      })

      clearAllAddFormFields()
    }
  }, [syncResult])

  const delayedMapLayersUpdate = () => {
    let tryingNumber = 0
    const newInterval = setInterval(async () => {
      tryingNumber += 1
      const isChangedSize = await updateLayersByMap(openedMapId)
      if (isChangedSize || tryingNumber === 5) {
        clearInterval(newInterval)
      }
    }, 1000)
  }

  const handleOKAddModal = () => {
    addModalForm?.submit()

    if (openedMapId && activeLayerId && selectedAddModalNumbersColumn) {
      dispatch(syncField({
        map_id: openedMapId,
        layer_id: activeLayerId,
        object_type: 'polygons',
        layer_type: LAYER_TYPE,
        area_type: selectedAddModalLandTypesColumn,
        field_num_column: selectedAddModalNumbersColumn
      }))
      delayedMapLayersUpdate()
    }
    clearAllAddFormFields()
  }

  const handleOKUpdateModal = () => {
    const layerObject = mapLayers.find(x => x?.layer_id_ghb === activeLayerId)
    if (openedMapId && layerObject && activeLayerId) {
      dispatch(syncField({
        map_id: openedMapId,
        layer_id: activeLayerId,
        object_type: 'polygons',
        layer_type: LAYER_TYPE,
        field_num_column: layerObject.geohub_column
      }))
      delayedMapLayersUpdate()
    }
    setIsOpenedUpdateModal(false)
  }

  const handleOKDeleteModal = async () => {
    try {
      const layerObject = mapLayers.find(x => x?.layer_id_ghb === activeLayerId)
      if (layerObject) {
        await request.patch({
          url: `/api/v1/mordovia/layer/${layerObject.id}/`,
          body: {
            is_deleted: true
          }
        })
        await updateLayersByMap(openedMapId)
      }
    } catch (error) {
      const status = error?.status
      switch (status) {
        case 403:
          notification.open({
            message: 'Удаление невозможно',
            description: 'Слой был настроен другим пользователем',
            placement: 'topRight',
            style: { background: '#F5222D66' },
            icon: <CloseCircleFilled style={{ color: '#F5222D' }} />,
          })
          break
      }
    } finally {
      setIsOpenedDeleteModal(false)
    }
  }

  const getLayerInfo = async (layerId, onlyColumns) => {
    try {
      const response = await request.get({
        url: `${GEOCODE_MAP_URL_BASE}/api/layers/${layerId}`
      })
      if (!onlyColumns && response?.extent) {
        let extentArray = []
        response.extent?.xMin > 0 && extentArray.push(response.extent?.xMin)
        response.extent?.yMin > 0 && extentArray.push(response.extent?.yMin)
        response.extent?.xMax > 0 && extentArray.push(response.extent?.xMax)
        response.extent?.yMax > 0 && extentArray.push(response.extent?.yMax)
        if (extentArray.length > 0) {
          setMapExtent(extentArray)
        }
      }
      if (Array.isArray(response?.columns)) {
        setColumnOptions(response.columns.map(dataCol => ({
          label: dataCol?.name,
          value: dataCol?.name
        })))
      }
    } finally {

    }
  }

  useEffect(() => {
    if (selectedAddModalLayer) {
      getLayerInfo(selectedAddModalLayer, true)
    }
  }, [selectedAddModalLayer])

  useEffect(() => {
    if (!isOpenedAddModal) {
      clearAllAddFormFields()
    }
  }, [isOpenedAddModal])

  const openLayer = (layerId) => {
    if (openedMapId && layerId) {
      history.push(`/verification-area/${openedMapId}/${layerId}`)
    }
  }

  const goBack = () => {
    if (openedGeohubLayerId) {
      history.push(`/verification-area/${openedMapId}`)
    } else {
      history.push('/')
    }
  }

  const downloadPhotos = async (row) => {
    try {
      const responce = await fetch(`/api/v1/verification_area/verification_area/${row.id}/download_photos/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      downloadFile(responce, row.polygon_num + '_photos.zip')
    } finally {
    }
  }

  const downloadDocuments = async (row) => {
    try {
      const responce = await fetch(`/api/v1/verification_area/verification_area/${row.id}/download_documents/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      downloadFile(responce, row.polygon_num + '_documents.zip')
    } finally {
    }
  }

  const menuClickHandler = (category, row) => {
    if (!category && !row) return
    if (openedGeohubLayerId) {
      setActiveVerificationId(row.id)
    } else {
      setActiveLayerId(row.layer_id_ghb)
    }
    switch (category) {
      case 'updateLayer':
        setIsOpenedUpdateModal(true)
        break
      case 'deleteLayer':
        setIsOpenedDeleteModal(true)
        break
      case 'attachPhotos':
        setIsOpenedAttachPhotosModal(true)
        break
      case 'showPhotos':
        setIsOpenedShowPhotosModal(true)
        break
      case 'attachDocuments':
        setIsOpenedAttachDocsModal(true)
        break
      case 'downloadPhotos':
        downloadPhotos(row)
        break
      case 'downloadDocuments':
        downloadDocuments(row)
        break
    }
  }

  const selectFilterOptionsHandler = (input, option) => {
    return (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())
  }

  const updatePagination = (page, filter, sort) => {
    setCurrentPage(page)
    updateLayerVerifications(openedAgrohubLayerId, page, filter, sort)
  }

  const closeUploadModal = () => {
    setIsOpenedAttachDocsModal(false)
    setIsOpenedAttachPhotosModal(false)
    setIsOpenedShowPhotosModal(false)
  }

  const uploadCancelHandler = () => {
    closeUploadModal()
  }

  const uploadConfirmHandler = (withOutClose) => {
    if (!withOutClose) {
      closeUploadModal()
    }
    updateLayerVerifications(openedAgrohubLayerId, 1)
  }

  const updateRow = useCallback(async (row, property, value) => {
    if (
      (row[property] === value) ||
      (typeof value !== 'string' && typeof value !== 'number')
    ) {
      return
    }
    try {
      const responce = await request.patch({
        url: `/api/v1/verification_area/verification_area/${row.id}/`,
        body: {
          [property]: value
        }
      })
      const updatedVerifications = visibleVerifications.map(item => {
        if (item.id === row.id) {
          return { ...item, ...responce }
        }
        return item
      })
      setVisibleVerifications(updatedVerifications)
    } finally {
    }
  })

  const setActiveField = (row) => {
    const { feature_id } = row?.polygon
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    if (feature_id && feature_id > 0) {
      const urlString = `${GEOCODE_MAP_URL_BASE}/api/layers/${openedGeohubLayerId}/features/${feature_id}`
      fetch(urlString, {
        mode: 'cors',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
      }).then((res) => res.json())
        .then((data) => {
          const { geometry } = data
          const { type, coordinates } = geometry

          const vectorSrc = new VectorSource()

          let featureInstance

          if (type === 'Point') {
            featureInstance = new LineString([coordinates, [parseFloat(coordinates[0]) + 0.0001, parseFloat(coordinates[1]) + 0.0001]])
          } else if (type === 'LineString') {
            featureInstance = new LineString(coordinates)
          } else if (type === 'MultiLineString') {
            featureInstance = new MultiLineString(coordinates)
          } else if (type === 'Polygon') {
            featureInstance = new Polygon(coordinates)
          } else if (type === 'MultiPoint') {
            featureInstance = new Polygon(coordinates?.length > 1 ? [coordinates] : [coordinates[0], [parseFloat(coordinates[0][0]) + 0.0001, parseFloat(coordinates[0][1]) + 0.0001]])
          } else if (type === 'MultiPolygon') {
            featureInstance = new MultiPolygon(coordinates)
          }

          const resFeat = new Feature({ geometry: featureInstance })

          vectorSrc.addFeature(resFeat)

          setMapExtent(vectorSrc.getExtent())
        })
    }
  }

  return (
    <Layout key="page-field-registry">
      <PanelLayout>
        <PanelHeader>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '20px'
            }}>
            <Tooltip
              placement="bottom"
              title="Вернуться на страницу “Карты“"
            >
              <Button onClick={goBack}>
                <ArrowLeftOutlined />
              </Button>
            </Tooltip>
            <Breadcrumb>
              {breadcrumbs}
            </Breadcrumb>
          </div>
        </PanelHeader>
        <PanelContent>
          <Map
            mapExtent={mapExtent}
            mapId={openedMapId}
            mapLayer={openedGeohubLayerId}
            style={{ width: '100%' }}
          />
          <Row style={{ height: '30px' }} />
          <CalculatorsTable
            menuClickHandler={menuClickHandler}
            openModal={setIsOpenedAddModal}
            openedLayer={openedGeohubLayerId}
            pageSize={DEFAULT_PAGE_SIZE}
            currentPage={currentPage}
            totalSize={totalSize}
            dataRows={openedGeohubLayerId ? visibleVerifications : visibleLayers}
            visibleLayers={visibleLayers}
            updatePagination={updatePagination}
            areaTypes={areaTypes}
            updateRow={updateRow}
            openLayer={openLayer}
            openField={setActiveField}
            tableLoading={tableLoading}
            className="map-table"
          />

          <Modal
            visible={isOpenedAddModal}
            title={(
              <div style={{ display: 'flex', gap: '5px' }}>
                <div>Подготовка полигонов к работе</div>
                <Tooltip title="Полигоны выбранного слоя станут доступны для внесения информации о них">
                  <QuestionCircleOutlined
                    style={{
                      display: 'grid',
                      placeItems: 'center',
                      color: 'rgba(0,0,0,0.45)'
                    }}
                  />
                </Tooltip>
              </div>
            )}
            onOk={handleOKAddModal}
            onCancel={clearAllAddFormFields}
            footer={[
              <Button key="back" onClick={clearAllAddFormFields}>
                Отменить
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={handleOKAddModal}
              >
                Применить
              </Button>
            ]}
          >
            <Form
              labelCol={{ span: 24 }}
              form={addModalForm}
            >
              <Form.Item
                name="layerName"
                label="Укажите наименование слоя"
                rules={[{ required: true, message: 'Пожалуйста, заполните поле' }]}
                tooltip="В списке отображаются только слои с объектами типа “полигон”"
              >
                <Select
                  showSearch
                  filterOption={selectFilterOptionsHandler}
                  placeholder="Выберите слой"
                  defaultValue={selectedAddModalLayer}
                  options={selectLayerOptions}
                  onChange={(newValue) => {
                    clearAddModalSelects()
                    setActiveLayerId(newValue)
                    setSelectedAddModalLayer(newValue)
                  }}
                />
              </Form.Item>

              <Form.Item
                name="numbersColumn"
                label="Столбец, в котором хранятся номера полигонов"
                rules={[{ required: true, message: 'Пожалуйста, заполните поле' }]}
                tooltip="Необходимо указать наименование столбца из таблицы kml-файла, загруженного на карту"
              >
                <Select
                  showSearch
                  filterOption={selectFilterOptionsHandler}
                  placeholder="Выберите столбец"
                  defaultValue={selectedAddModalNumbersColumn}
                  style={{ width: '100%' }}
                  options={columnOptions}
                  onChange={setSelectedAddModalNumbersColumn}
                />
              </Form.Item>

              <Form.Item
                name="cadastralNumbersColumn"
                label="Столбец, в котором хранятся кадастровые номера полигонов"
                tooltip="Необходимо указать наименование столбца из таблицы kml-файла, загруженного на карту"
              >
                <Select
                  showSearch
                  filterOption={selectFilterOptionsHandler}
                  placeholder="Выберите столбец"
                  defaultValue={selectedAddModalCadastralNumbersColumn}
                  style={{ width: '100%' }}
                  options={columnOptions}
                  onChange={setSelectedAddModalCadastralNumbersColumn}
                />
              </Form.Item>

              <Form.Item
                name="landTypesColumn"
                label="Столбец, в котором хранятся установленные виды угодий"
                tooltip="Необходимо указать наименование столбца из таблицы kml-файла, загруженного на карту"
              >
                <Select
                  showSearch
                  filterOption={selectFilterOptionsHandler}
                  placeholder="Выберите столбец"
                  defaultValue={selectedAddModalLandTypesColumn}
                  style={{ width: '100%' }}
                  options={columnOptions}
                  onChange={setSelectedAddModalLandTypesColumn}
                />
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            visible={isOpenedUpdateModal}
            title="Обновление слоя"
            onOk={handleOKUpdateModal}
            onCancel={() => setIsOpenedUpdateModal(false)}
            footer={[
              <Button key="back" onClick={() => setIsOpenedUpdateModal(false)}>
                Отменить
              </Button>,
              <Button key="submit"
                onClick={handleOKUpdateModal}
                type="primary"
              >
                Применить
              </Button>
            ]}
          >
            <div className="warning-red">
              <span style={{ color: 'red' }}>!</span>
              Убедитесь, что выбранный слой также был изменен на карте
            </div>
            <div style={{ marginTop: '15px' }} className="maintext">
              Обновить объекты выбранного слоя?
            </div>
          </Modal>

          <Modal
            visible={isOpenedDeleteModal}
            title='Удаление слоя'
            onOk={handleOKDeleteModal}
            onCancel={() => setIsOpenedDeleteModal(false)}
            footer={[
              <Button key="back" onClick={() => setIsOpenedDeleteModal(false)}>
                Отменить
              </Button>,
              <Button key="submit"
                onClick={handleOKDeleteModal}
                danger type="primary"
              >
                Удалить
              </Button>
            ]}
          >
            <div className="maintext">
              Удалить выбранный слой и вложенные объекты?
            </div>
            <div style={{ marginBottom: '25px' }} className="maintext">
              Действие нельзя будет отменить.
            </div>
            <div className="warning-red">
              <span style={{ color: 'red' }}>!</span>
              После удаления вложенные полигоны станут недоступны для использования при работе в системе
            </div>
          </Modal>

          {(isOpenedAttachDocsModal || isOpenedAttachPhotosModal || isOpenedShowPhotosModal) && <UploadModal
            isBorshevik={false}
            activeItem={activeVerificationId}
            isPhotoReport={isOpenedAttachPhotosModal || isOpenedShowPhotosModal}
            isInitedViewer={isOpenedShowPhotosModal}
            cancelHandler={uploadCancelHandler}
            confirmHandler={uploadConfirmHandler}
          />}
        </PanelContent>
      </PanelLayout>
    </Layout>
  )
}

export default FieldRegistry
