import React from 'react'
import { Button, Row, Col } from 'antd'

import '../style.less'

export default ({
  openModal
}) => {
  return (
    <>
      <div className="empty-info-box">
        <div style={{ marginBottom: '15px' }}>
          Для дальнейшей работы в системе выполните настройку слоя и его объектов. Перед подготовкой объектов убедитесь, что слой точек отбора проб был загружен на карту.
        </div>
      </div>
      <Row className="button-row">
        <Col span={9} />
        <Col span={5}>
          <Button onClick={() => openModal(true)} type="primary">
            Настроить слой для заполнения
          </Button>
        </Col>
        <Col span={10} />
      </Row>
    </>
  )
}
